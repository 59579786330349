<template>
  <div class="backupManagement">
    <el-scrollbar class="elScroll">
      <div class="search_sList">
        <el-tabs v-model="activeName">
          <el-tab-pane label="备份管理" name="first"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="table_sList">
        <div class="table_btn_area">
          <el-button
            type="primary"
            @click="implementEvent"
            plain
            >执行备份</el-button
          >
          <el-button
            type="primary"
            @click="dialogDiyEvent('submit')"
            plain
            >备份设置</el-button
          >
        </div>
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
          align="center"
        >
          <el-table-column
            label="序号"
            width="60"
            align="center">
            <template slot-scope="scope">
              {{
                (backupForm.page - 1) * backupForm.pageSize + scope.$index + 1
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="操作日期"
            align="center"
          >
            <template slot-scope="scope">
              {{$moment(Number(scope.row.createTime)).format("HH:mm:ss")}}
            </template>
          </el-table-column>
          <el-table-column
            prop="createBy"
            label="操作人"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="fileName"
            label="文件名"
            align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="140"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                @click="deleteEvent(scope.row)"
                type="text"
                class="pub_style"
              >删除</el-button>
              <el-button
                @click="resetEvent(scope.row)"
                type="text"
                class="pub_style"
              >还原</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          @changePage="changePage"
          :childMsg="childMsg"
        ></Pagination>
      </div>
    </el-scrollbar>
    <dialogDiy
      :isShow="isShowBackUp"
      :dialogDiyData="dialogDiyData"
      @dialogEvent="dialogDiyEvent"
    >
      <div class="backup_area" slot="dialogContent">
        <el-form
          :model="formInline"
          class="login_form"
          label-width="130px"
          :disabled="$route.query.status == 'details'"
        >
          <el-form-item label="备份方式:">
            <el-select
              v-model="formInline.type"
              placeholder="全部"
              @change="changeType"
              filterable
            >
              <el-option label="手动备份" :value="0"></el-option>
              <el-option label="自动备份" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <template v-if="formInline.type == 1">
            <el-form-item label="备份周期:">
              每隔
              <el-input
                class="ls_input"
                v-model="formInline.days"
                maxlength="5"
                clearable>
              </el-input>
              天，对系统自动进行备份
              <div>
                 <el-radio-group v-model="formInline.weekDay">
                  <el-radio :label="1">星期一</el-radio>
                  <el-radio :label="2">星期二</el-radio>
                  <el-radio :label="3">星期三</el-radio>
                  <el-radio :label="4">星期四</el-radio>
                  <el-radio :label="5">星期五</el-radio>
                  <el-radio :label="6">星期六</el-radio>
                  <el-radio :label="7">星期日</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="备份开始时间:">
              <el-time-picker
                @change="changeTime"
                is-range
                v-model="timeVal"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
              </el-time-picker>
            </el-form-item>
            <el-form-item label="快照备份保留天数:">
              <el-input
                class="ls_input"
                v-model="formInline.retainedDays"
                maxlength="5"
                clearable>
              </el-input>
              天（最大730天）
            </el-form-item>
          </template>
        </el-form>
        <div class="backup-btn">
          <el-button type="primary" @click="setBackUpData">确 定</el-button>
          <el-button @click="dialogDiyEvent">取 消</el-button>
        </div>
      </div>
    </dialogDiy>

    <dialogDiy
      :isShow="isShowBack"
      :dialogDiyData="dialogBackDiyData"
      @dialogEvent="isShowBack = false"
    >
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="" />
        <p>{{title}}</p>
        <div class="btn_area">
          <el-button @click="isShowBack = false">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
  </div>
</template>

<script>
import Pagination from '../Pagination'
import dialogDiy from '../commonModule/dialogDiy'
import way from '../../api/encapsulation'
export default {
  name: 'backupManagement',
  components: {
    Pagination,
    dialogDiy
  },
  data() {
    return {
      activeName: 'first',
      backupForm: {
        page: 1,
        pageSize: 10
      },
      timeVal: null,
      formInline: {
        days: null,
        endTime: null,
        retainedDays: null,
        startTime: null,
        type: 0,
        weekDay: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      isShowBackUp: false,
      dialogDiyData: {
        title: "设置备份",
        sizeStyle: { width: "800px", height: "250px" },
      },
      isShowBack: false,
      title: null,
      dialogBackDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '234px'}
      },
      rowData: null,
    }
  },
  created() {
    this.getBackUpList()
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 获取备份数据
    getBackUpList() {
      this.$request.backUpList(this.backupForm).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    getbackUpData() {
      this.$request.backUpData().then(res => {
        if(res.data.code == 0) {
          let data = res.data.data;
          this.formInline.days = data.days
          this.formInline.endTime = data.endTime
          this.formInline.retainedDays = data.retainedDays
          this.formInline.startTime = data.startTime
          this.formInline.type = data.type
          this.formInline.weekDay = data.weekDay || 1
          this.timeVal = data.startTime ? [data.startTime, data.endTime] : null
          this.dialogDiyData.sizeStyle.height = data.type == 1 ? '450px' : '250px'
        }
      })
    },
    changeTime() {
      // console.log(this.timeVal)
      this.formInline.startTime = this.timeVal[0];
      this.formInline.endTime = this.timeVal[1];
    },
    changePage(val) {
      this.childMsg = val;
      this.backupForm.page = val.current;
      this.backupForm.pageSize = val.size;
      this.getBackUpList();
    },
    implementEvent() {
      let progress = 0;
      this.$store.commit('changeIsProgress', true);
      this.timer = setInterval(() => {
        if(progress > 1) {
          this.$store.commit('changeIsProgress', false);
          this.$store.commit('changeProgress', 0);
          this.$store.commit('changePogressTips', '');
          clearInterval(this.timer)
          this.backUpDatas()
        } else {
          this.$store.commit('changePogressTips', '正在备份中，请勿退出系统');
          this.$store.commit('changeProgress', progress);
          progress+=0.2;
        }
      }, 1000)
    },
    // 备份
    backUpDatas() {
      this.$request.backUpDatas().then(res => {
        if(res.data.code == 0) {
          this.getBackUpList();
          this.Success('备份成功');
        }
      })
    },
    dialogDiyEvent(type) {
      if(type == 'submit') this.getbackUpData();
      this.isShowBackUp = !this.isShowBackUp;
    },
    setBackUpData() {
      this.$request.setBackUpData(this.formInline).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.dialogDiyEvent();
        }
      })
    },
    changeType() {
      if(this.formInline.type == 1) {
        this.dialogDiyData.sizeStyle.height = '450px'
      } else {
        this.dialogDiyData.sizeStyle.height = '250px'
      }
    },
    deleteEvent(row) {
      this.title = '确定要删除该备份吗？'
      this.rowData = row
      this.rowType = 'delete'
      this.isShowBack = !this.isShowBack
    },
    resetEvent(row) {
      this.title = '确定要还原该备份吗？'
      this.rowData = row
      this.rowType = 'set'
      this.isShowBack = !this.isShowBack
    },
    doEvent() {
      if(this.rowType == 'delete') {
        this.$request.deleteBackUpData({id: this.rowData.id}).then(res => {
          if(res.data.code == 0) {
            this.Success('删除成功');
            this.getBackUpList();
            this.isShowBack = !this.isShowBack
          }
        })
      } else {
        this.$request.resetBackUpData({id: this.rowData.id}).then(res => {
          if(res.data.code == 0) {
            this.Success(res.data.data);
            this.getBackUpList();
            this.isShowBack = !this.isShowBack
          }
        })
      }
    }
  },
}
</script>

<style lang='less'>
  .backupManagement {
    height: 100%;
    background-color: #fff;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px 0;
    }
    .table_sList {
      box-sizing: border-box;
      padding: 0 40px 30px;
      margin-top: 10px;
      background-color: #fff;
      .table_btn_area {
        margin-bottom: 10px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
        border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .pop_del {
        margin-left: 6px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .ls_input {
      width: 100px;
      & > input {
        width: inherit;
      }
    }
    .backup_area {
      padding: 20px 40px;
      .backup-btn {
        text-align: center;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
  }
</style>